// FoodCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import { formatCurrency } from "src/utils/formatCurrency";

interface FoodCardProps {
  image: string;
  name: string;
  price: number;
  originalPrice?: number | null;
}

const FoodCard: React.FC<FoodCardProps> = ({
  image,
  name,
  price,
  originalPrice,
}) => {
  return (
    <Card
      sx={{
        maxWidth: 200,
        textAlign: "center",
        borderRadius: "8px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Box p={1}>
        <CardMedia
          component="img"
          alt={name}
          image={image}
          sx={{ height: 140, borderRadius: "8px" }}
        />
      </Box>
      <CardContent>
        <Box height={50}>
          <Typography variant="body1" fontWeight={550} fontSize={14}>
            {name}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Stack>
            <Typography variant="body2" color="text.secondary" fontWeight={550}>
              {formatCurrency(price)}
            </Typography>
            {originalPrice && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textDecoration: "line-through", color: "red" }}
              >
                {formatCurrency(originalPrice)}
              </Typography>
            )}
          </Stack>
          <IconButton
            color="primary"
            aria-label="add to cart"
            sx={{
              backgroundColor: "#ff7840",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#ff7840",
              },
            }}
          >
            <FaPlus fontSize={13} color="#fff" />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FoodCard;
