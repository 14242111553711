import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import table from "./assets/images/round-table.png";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { CartItem } from "./interface";
import { createCart } from "./service";
import { translations } from "src/constants/translate";
import { useLanguage } from "src/context/LanguageProvider";

const Table = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [displayValue, setDisplayValue] = useState("");

  const handleButtonClick = (value: string) => {
    if (displayValue.length < 2) {
      setDisplayValue((prev) => {
        if (prev === "0") {
          return value;
        }
        return prev + value;
      });
    }
  };

  const handleClear = () => {
    setDisplayValue("");
  };

  const handleOk = async () => {
    if (displayValue) {
      localStorage.setItem("tableNumber", displayValue);
    }

    const cartData: CartItem[] = JSON.parse(
      localStorage.getItem("cart") || "[]"
    );

    if (cartData.length === 0) {
      console.error("Cart is empty, cannot create cart.");
      return;
    }

    const productsToSend = cartData.map((item: CartItem) => ({
      sku: item.sku,
      product_id: item.product_id,
      amount: item.amount,
      topping_ids: item.topping_ids,
    }));

    try {
      const response = await createCart(productsToSend);
      const cartIds = response.map((item: { _id: string }) => item._id);
      localStorage.setItem("cart_ids", JSON.stringify(cartIds));

      navigate("/payment");
    } catch (error) {
      console.error("Error creating cart:", error);
    }
  };

  const handleReturn = () => {
    navigate("/list");
  };

  return (
    <Box
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "650px",
      }}
      p={1}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <IoIosArrowBack fontSize={22} onClick={handleReturn} />
        <Typography variant="body1" fontWeight={550} fontSize={19} mb={0.2}>
          {translations[language as "en" | "jp" | "vn"].c_table}
        </Typography>
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0 auto" }}
        p={2}
        width={300}
        position="relative"
      >
        <Box
          position="relative"
          width={180}
          height={180}
          mb={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={table}
            alt="table"
            width={200}
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography
            variant="h3"
            color="#333"
            sx={{
              position: "absolute",
              fontWeight: "bold",
              fontSize: "40px",
            }}
          >
            {displayValue || "0"}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {Array.from({ length: 9 }, (_, i) => (
            <Grid item xs={4} key={i + 1}>
              <Button
                variant="contained"
                fullWidth
                disabled={displayValue.length >= 2}
                onClick={() => handleButtonClick((i + 1).toString())}
                sx={{ height: 58, bgcolor: "#ff7840" }}
              >
                {i + 1}
              </Button>
            </Grid>
          ))}
          <Grid item xs={4}>
            <Button
              variant="outlined"
              fullWidth
              color="error"
              onClick={handleClear}
              sx={{ height: 58 }}
            >
              {translations[language as "en" | "jp" | "vn"].clear}
            </Button>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOk}
              sx={{ height: 58, bgcolor: "#ff7840" }}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Table;
