import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import uk from "../../assets/images/flag/united-kingdom.png";
import jp from "../../assets/images/flag/japan.png";
import vn from "../../assets/images/flag/vietnam.png";
import { useLanguage } from "src/context/LanguageProvider";

import logo from "../../assets/images/ikipos_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

const CustomAppBar: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value as string);
  };

  const handleLogoClick = () => {
    if (location.pathname === "/confirmation") {
      localStorage.clear();
      navigate("/");
    }
  };

  return (
    <AppBar
      position="static"
      variant="outlined"
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "650px",
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        mb: 1,
      }}
    >
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <img src={logo} alt="" width={200} onClick={handleLogoClick} />
          <Select
            value={language}
            onChange={handleLanguageChange}
            variant="standard"
            sx={{
              color: "#333",
              border: "none",
              fontWeight: 550,
              fontSize: 18,
              ".MuiSelect-icon": { color: "inherit", border: "none" },
            }}
          >
            <MenuItem value="en">
              <Stack direction="row" alignItems="center" gap={1}>
                <img src={uk} alt="English" style={{ width: 24, height: 24 }} />
                <Typography variant="body2" fontWeight={550} fontSize={16}>
                  English
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value="jp">
              <Stack direction="row" alignItems="center" gap={1}>
                <img
                  src={jp}
                  alt="Japanese"
                  style={{ width: 24, height: 24 }}
                />
                <Typography variant="body2" fontWeight={550} fontSize={16}>
                  日本語
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value="vn">
              <Stack direction="row" alignItems="center" gap={1}>
                <img
                  src={vn}
                  alt="Vietnamese"
                  style={{ width: 24, height: 24 }}
                />
                <Typography variant="body2" fontWeight={550} fontSize={16}>
                  Tiếng Việt
                </Typography>
              </Stack>
            </MenuItem>
          </Select>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
