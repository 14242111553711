import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import IconLabelButton from "./components/IconLabelButtonprops";
import FoodCard from "./components/ListCard";
import { Masonry } from "@mui/lab";
import { Product } from "./interface";
import { createCart, getConfigCategory, getFoodDetail } from "./service";
import { useLanguage } from "src/context/LanguageProvider";
import { Link, useNavigate } from "react-router-dom";
import "./assets/css/List.css";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { formatCurrency } from "src/utils/formatCurrency";
import { FaMinus, FaPlus, FaArrowLeft } from "react-icons/fa6";
import { IoTrashOutline, IoClose } from "react-icons/io5";
import { FcHome } from "react-icons/fc";
import { CartItem } from "../Table/interface";
import { BsCart4 } from "react-icons/bs";
import { translations } from "src/constants/translate";
import { useSnackbar } from "src/components/CustomSnackbar";
import { BranchIT } from "../Branch/interface";
import { getBranchConfig } from "../Branch/service";

const List = () => {
  const { language } = useLanguage();
  const { showSnackbar } = useSnackbar();

  const boxRef = useRef<HTMLDivElement | null>(null);
  const otherBoxRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cart, setCart] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [activeVariant, setActiveVariant] = useState<any | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedToppings, setSelectedToppings] = useState<any[]>([]);
  const [toppings, setToppings] = useState<any[]>([]);
  const [loadingToppings, setLoadingToppings] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);

  const diningChoice = localStorage.getItem("diningChoice");

  const branchId = localStorage.getItem("branch_id");

  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:400px)");

  useEffect(() => {
    if (categories.length > 0) {
      setActiveButton(categories[0].category[0]);
      setSelectedCategory(categories[0].category[0]);
    }
  }, [categories]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "[]");
    setCart(storedCart);
  }, []);

  useEffect(() => {
    if (selectedProduct && selectedProduct.variants.length > 0) {
      setActiveVariant(selectedProduct.variants[0]);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const fetchBranchDetails = async () => {
      const params = new URLSearchParams(window.location.search);
      const branchId = params.get("branch_id");

      if (branchId) {
        try {
          const data = await getBranchConfig("branch");
          const branch = data.config.option_content.find(
            (b: BranchIT) => b.key === branchId
          );

          if (branch) {
            localStorage.setItem("branch_id", branchId);
            localStorage.setItem("bin", branch.bin);
            localStorage.setItem("logo", branch.logo);
            localStorage.setItem("card_id", branch.card_id);
            localStorage.setItem("card_name", branch.card_name);
            localStorage.setItem("shortName", branch.shortName);
          } else {
            console.warn("Branch not found for branch_id:", branchId);
          }
        } catch (error) {
          console.error("Error fetching branches:", error);
        }
      }
    };

    fetchBranchDetails();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const listCategory = await getConfigCategory(branchId || "");
        setCategories(listCategory.data.config.option_content);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoadingProducts(true);
      try {
        if (activeButton === "home" || selectedCategory === "") {
          const listCard = await getFoodDetail();
          setProducts(listCard.data);
        } else {
          const listCard = await getFoodDetail(selectedCategory);
          setProducts(listCard.data);
        }
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, [activeButton, selectedCategory]);

  useEffect(() => {
    if (isCartOpen || isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isCartOpen, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setCartOpen(false);
      }
    };

    if (isCartOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCartOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        otherBoxRef.current &&
        !otherBoxRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const updateCart = (updatedCart: any) => {
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  const handleIncreaseAmount = (index: any) => {
    const updatedCart = [...cart];
    updatedCart[index].amount += 1;

    const toppingTotalPrice = updatedCart[index].topping_ids.reduce(
      (total: number, topping: any) => total + topping.price,
      0
    );

    const productPrice =
      updatedCart[index].discountedPrice || updatedCart[index].price;

    updatedCart[index].totalPrice =
      (productPrice + toppingTotalPrice) * updatedCart[index].amount;

    updateCart(updatedCart);
  };

  const handleDecreaseAmount = (index: any) => {
    const updatedCart = [...cart];
    if (updatedCart[index].amount > 1) {
      updatedCart[index].amount -= 1;

      const toppingTotalPrice = updatedCart[index].topping_ids.reduce(
        (total: number, topping: any) => total + topping.price,
        0
      );

      const productPrice =
        updatedCart[index].discountedPrice || updatedCart[index].price;

      updatedCart[index].totalPrice =
        (productPrice + toppingTotalPrice) * updatedCart[index].amount;

      updateCart(updatedCart);
    }
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      return total + (item.totalPrice || item.price * item.amount);
    }, 0);
  };

  const totalPrice = calculateTotalPrice();

  const handleRemoveItem = (index: any) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    updateCart(updatedCart);

    if (updatedCart.length === 0) {
      setCartOpen(false);
    }
  };

  const handleHomeClick = () => {
    setActiveButton("home");
    setSelectedCategory("");
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const toggleCart = () => {
    setCartOpen((prev) => !prev);
  };

  const handleToggleTopping = (topping: any) => {
    const isSelected = selectedToppings.some((t) => t._id === topping._id);

    if (isSelected) {
      setSelectedToppings((prev) => prev.filter((t) => t._id !== topping._id));
    } else {
      setSelectedToppings((prev) => [...prev, topping]);
    }
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleOk = async () => {
    const cartData: CartItem[] = JSON.parse(
      localStorage.getItem("cart") || "[]"
    );

    if (cartData.length === 0) {
      console.error("Cart is empty, cannot create cart.");
      return;
    }

    const productsToSend = cartData.map((item: CartItem) => ({
      sku: item.sku,
      product_id: item.product_id,
      amount: item.amount,
      topping_ids: item.topping_ids,
    }));

    try {
      setLoading(true); // Bật loading
      const response = await createCart(productsToSend);
      const cartIds = response.map((item: { _id: string }) => item._id);
      localStorage.setItem("cart_ids", JSON.stringify(cartIds));

      navigate("/payment");
    } catch (error) {
      console.error("Error creating cart:", error);
    } finally {
      setLoading(false); // Tắt loading khi xong
    }
  };

  const handleContinue = async () => {
    const diningChoice = localStorage.getItem("diningChoice");
    const tableNumber = localStorage.getItem("tableNumberQR");

    if (diningChoice === "at the restaurant") {
      await handleOk();
    }

    if (diningChoice === "take away") {
      await handleOk();
    } else if (tableNumber) {
      navigate("/payment");
    } else {
      navigate("/table");
    }
  };

  const handleAddToCart = () => {
    if (!selectedProduct) {
      console.error("No product selected");
      return;
    }

    const selectedVariant = activeVariant || selectedProduct.variants[0];
    if (!selectedVariant) {
      console.error("No variant selected");
      return;
    }

    const hasCoupon =
      selectedProduct.coupon_data && selectedProduct.coupon_data.promotion > 0;
    const isPercentage =
      hasCoupon && selectedProduct.coupon_data.promotion_type === "percentage";
    const discountAmount = hasCoupon
      ? isPercentage
        ? (selectedVariant.price * selectedProduct.coupon_data.promotion) / 100
        : selectedProduct.coupon_data.promotion
      : 0;

    const discountedPrice = selectedVariant.price - discountAmount;

    const selectedToppingsData = selectedToppings.map((topping) => ({
      _id: topping._id,
      price: topping.price,
      name: topping.name,
    }));

    const toppingTotalPrice = selectedToppings.reduce((total, topping) => {
      return total + topping.price;
    }, 0);

    const totalProductPrice = (discountedPrice + toppingTotalPrice) * quantity;

    const productToAdd = {
      sku: selectedVariant.sku,
      product_id: selectedProduct._id,
      amount: quantity,
      topping_ids: selectedToppingsData,
      image: selectedProduct.url,
      name: selectedProduct.name,
      description: selectedProduct.description,
      size: selectedVariant.size,
      price: selectedVariant.price,
      discountedPrice: discountedPrice,
      totalPrice: totalProductPrice,
      coupon_data: hasCoupon ? selectedProduct.coupon_data : null,
    };

    const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");

    const existingProductIndex = currentCart.findIndex(
      (item: any) =>
        item.product_id === selectedProduct._id &&
        item.sku === selectedVariant.sku &&
        JSON.stringify(item.topping_ids) ===
          JSON.stringify(selectedToppingsData)
    );

    if (existingProductIndex !== -1) {
      currentCart[existingProductIndex].amount += quantity;
      currentCart[existingProductIndex].totalPrice =
        (currentCart[existingProductIndex].discountedPrice +
          toppingTotalPrice) *
        currentCart[existingProductIndex].amount;
    } else {
      currentCart.push(productToAdd);
    }

    localStorage.setItem("cart", JSON.stringify(currentCart));

    setCart(currentCart);

    showSnackbar(
      `${selectedProduct.name} ${
        translations[language as "en" | "jp" | "vn"].notification_addToCart
      }`,
      "success"
    );

    setIsOpen(false);
  };

  const buttonText =
    diningChoice === "at the restaurant"
      ? `${translations[language as "en" | "jp" | "vn"].continue}`
      : `${translations[language as "en" | "jp" | "vn"].payment}`;

  const skeletonWidth = isExtraSmallScreen ? 165 : 180;
  const skeletonHeight = 200;

  const boxHeight = isExtraSmallScreen
    ? "225vh"
    : isSmallScreen
    ? "160vh"
    : "100vh";

  const handleReturn = () => {
    localStorage.removeItem("diningChoice");
    navigate("/choose");
  };

  return (
    <Box
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "600px",
      }}
      p={1}
      height={boxHeight}
    >
      <Button
        onClick={handleReturn}
        startIcon={<FaArrowLeft />}
        sx={{ color: "#333", mb: 2 }}
      >
        {translations[language as "en" | "jp" | "vn"].return}
      </Button>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {categories.map((category) => (
            <IconLabelButton
              key={category._id}
              imgSrc={category.image}
              label={
                language === "vn"
                  ? category.desVi
                  : language === "jp"
                  ? category.desJp
                  : category.desEn
              }
              isActive={activeButton === category.category[0]}
              onClick={() => {
                setActiveButton(category.category[0]);
                if (category.category.length === 0) {
                  setSelectedCategory("");
                } else {
                  setSelectedCategory(category.category[0]);
                }
              }}
            />
          ))}
        </Stack>
      </Box>
      {/* <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography
          variant="body2"
          sx={{ textAlign: "center" }}
          fontWeight={550}
          fontSize={20}
        >
          {selectedCategory
            ? language === "vn"
              ? categories.find((cat) => cat.category[0] === selectedCategory)
                ?.desVi
              : language === "jp"
                ? categories.find((cat) => cat.category[0] === selectedCategory)
                  ?.desJp
                : categories.find((cat) => cat.category[0] === selectedCategory)
                  ?.desEn
            : `${translations[language as "en" | "jp" | "vn"].all}`}
        </Typography>

      </Stack> */}
      <Box mt={5}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {loadingProducts ? (
            <Grid
              container
              spacing={2}
              style={{ width: "100%", maxWidth: "1200px" }}
            >
              {[...Array(6)].map((_, index) => (
                <Grid item xs={6} sm={4} key={index}>
                  {" "}
                  {/* Sử dụng Grid để chia thành 2 cột */}
                  <Skeleton
                    variant="rectangular"
                    width="100%" // Đặt chiều rộng 100% cho Skeleton
                    height={skeletonHeight}
                    animation="wave"
                    style={{ borderRadius: "10px" }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              style={{ width: "100%", maxWidth: "1200px" }}
            >
              {products.map((product) => {
                const originalPrice = product.variants[0]?.price || 0;

                const coupon = product.coupon_data;
                const hasPromotion =
                  coupon && coupon.promotion_type === "percentage";

                const discountedPrice = hasPromotion
                  ? originalPrice * (1 - coupon.promotion / 100)
                  : originalPrice;

                return (
                  <Grid item xs={6} sm={4} key={product._id}>
                    {" "}
                    <div
                      onClick={() => {
                        setSelectedProduct(product);
                        setIsOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <FoodCard
                        image={product.url}
                        name={product.name}
                        price={discountedPrice}
                        originalPrice={hasPromotion ? originalPrice : null}
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
      </Box>

      {cart.length > 0 && (
        <Box
          sx={{
            display: "block",
            margin: "0 auto",
            width: "100%",
            maxWidth: "600px",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              onClick={toggleCart}
              direction="row"
              alignItems="center"
              gap={1}
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Box mt={2} ml={1} mb={2}>
                <Badge
                  badgeContent={cart.length}
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <BsCart4 fontSize={25} color="#ff7840" />
                </Badge>
              </Box>
              <Typography
                variant="body1"
                color="#ff7840"
                mr={1}
                fontWeight={550}
              >
                {formatCurrency(
                  cart.reduce((total, item) => total + item.totalPrice, 0)
                )}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                textTransform: "unset",
                borderRadius: "unset",
                width: "150px",
                height: "58px",
                bgcolor: "#ff7840",
              }}
              onClick={handleContinue}
            >
              {translations[language as "en" | "jp" | "vn"].payment}
            </Button>
          </Stack>
        </Box>
      )}

      {isCartOpen && (
        <Box>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 998,
            }}
            onClick={() => setCartOpen(false)}
          />
          <Box
            ref={boxRef}
            sx={{
              display: "block",
              margin: "0 auto",
              width: "100%",
              maxWidth: "600px",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              height: "500px",
              backgroundColor: "white",
              boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
              zIndex: 999,
              textAlign: "center",
            }}
            className={isCartOpen ? "box-add" : "box-close"}
          >
            <Box
              sx={{
                position: "relative",
                padding: "8px 16px",
                borderBottom: "1px solid rgba(3,3,3,0.1)",
              }}
            >
              <Typography
                variant="body1"
                fontSize={16}
                fontWeight={550}
                align="center"
                sx={{ textAlign: "center" }}
              >
                {translations[language as "en" | "jp" | "vn"].c_cart}
              </Typography>

              <IconButton
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setCartOpen(false)}
              >
                <IoClose />
              </IconButton>
            </Box>
            <Stack
              spacing={2}
              sx={{
                maxHeight: "380px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
              }}
            >
              {cart.length > 0 ? (
                cart.map((item: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                      pb: 1,
                      p: 1,
                    }}
                  >
                    <Stack direction="row" gap={2}>
                      <img src={item.image} alt="food" width={70} />
                      <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight={550}
                            textAlign="start"
                          >
                            {item.name}
                          </Typography>

                          <Typography variant="body2" textAlign="start">
                            {
                              translations[language as "en" | "jp" | "vn"]
                                .c_size
                            }
                            : <strong>{item.size}</strong>
                          </Typography>
                          {item.topping_ids.length > 0 && (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              textAlign="start"
                              fontSize={12}
                            >
                              {
                                translations[language as "en" | "jp" | "vn"]
                                  .topping
                              }
                              :{" "}
                              {item.topping_ids
                                .map((topping: any) => topping.name)
                                .join(", ")}
                            </Typography>
                          )}
                        </Box>

                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <IoTrashOutline color="red" fontSize={20} />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={2}
                          ml={10.5}
                          sx={{ border: "1px solid rgba(3,3,3,0.1)" }}
                        >
                          <Button
                            onClick={() => handleDecreaseAmount(index)}
                            sx={{
                              height: 30,
                              minWidth: 30,
                              backgroundColor: "transparent",
                              color: "#333",
                              borderRight: "1px solid rgba(3,3,3,0.1)",
                              borderRadius: "unset",
                            }}
                          >
                            <FaMinus />
                          </Button>
                          <Typography variant="body2">{item.amount}</Typography>
                          <Button
                            onClick={() => handleIncreaseAmount(index)}
                            sx={{
                              height: 30,
                              minWidth: 30,
                              backgroundColor: "transparent",
                              color: "#333",
                              borderLeft: "1px solid rgba(3,3,3,0.1)",
                              borderRadius: "unset",
                            }}
                          >
                            <FaPlus />
                          </Button>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant="body2" fontWeight={550}>
                          {formatCurrency(item.totalPrice)}
                        </Typography>
                        {item.coupon_data && (
                          <Typography
                            variant="body2"
                            sx={{
                              textDecoration: "line-through",
                              color: "red",
                            }}
                          >
                            {formatCurrency(item.price * item.amount)}{" "}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  {translations[language as "en" | "jp" | "vn"].noDTcart}
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      )}

      {isOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
      <Box
        sx={{
          display: "block",
          margin: "0 auto",
          width: "100%",
          maxWidth: "650px",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: "450px",
          backgroundColor: "white",
          boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1001,
          textAlign: "center",
        }}
        className={isOpen ? "box-add" : "box-close"}
      >
        {isOpen && selectedProduct ? (
          <Box
            sx={{
              display: "block",
              margin: "0 auto",
              width: "100%",
              maxWidth: "650px",
            }}
            ref={otherBoxRef}
          >
            <Box
              sx={{
                position: "relative",
                padding: "8px 16px",
                borderBottom: "1px solid rgba(3,3,3,0.1)",
              }}
            >
              <Typography
                variant="body1"
                fontSize={16}
                fontWeight={550}
                align="center"
                sx={{ textAlign: "center" }}
              >
                {translations[language as "en" | "jp" | "vn"].c_new}
              </Typography>

              <IconButton
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setIsOpen(false)}
              >
                <IoClose />
              </IconButton>
            </Box>

            <Box pl={2} pr={2}>
              <Typography
                variant="body1"
                fontWeight={550}
                fontSize={20}
                mt={1}
                mb={2}
              >
                {selectedProduct.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={2}>
                {translations[language as "en" | "jp" | "vn"].size}
              </Typography>
              <Stack direction="row" gap={2} mt={1}>
                {selectedProduct.variants.map((variant: any) => {
                  const hasCoupon =
                    selectedProduct.coupon_data &&
                    selectedProduct.coupon_data.promotion > 0;
                  const isPercentage =
                    hasCoupon &&
                    selectedProduct.coupon_data.promotion_type === "percentage";
                  const discountAmount = hasCoupon
                    ? isPercentage
                      ? (variant.price *
                          selectedProduct.coupon_data.promotion) /
                        100
                      : selectedProduct.coupon_data.promotion
                    : 0;

                  const discountedPrice = variant.price - discountAmount;

                  return (
                    <Button
                      key={variant._id}
                      sx={{
                        borderRadius: "2px",
                        color: "#333",
                        textTransform: "none",
                        border:
                          activeVariant?._id === variant._id
                            ? "1px solid #ff7840"
                            : "1px solid rgba(3,3,3,0.2)",
                      }}
                      onClick={() => setActiveVariant(variant)}
                    >
                      <Stack direction="column">
                        <Typography variant="body2" fontSize={13}>
                          {translations[language as "en" | "jp" | "vn"].c_size}:{" "}
                          <strong>{variant.size}</strong>
                        </Typography>
                        {hasCoupon ? (
                          <Stack direction="column" alignItems="center">
                            <Typography variant="body1">
                              {formatCurrency(discountedPrice)}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              {formatCurrency(variant.price)}
                            </Typography>
                          </Stack>
                        ) : (
                          <Typography variant="body1">
                            {formatCurrency(variant.price)}
                          </Typography>
                        )}
                      </Stack>
                    </Button>
                  );
                })}
              </Stack>

              <Stack direction="row" alignItems="center" gap={2} mt={2}>
                <Typography variant="body2" color="text.secondary">
                  {translations[language as "en" | "jp" | "vn"].amount}:
                </Typography>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: "10px",
                      height: 30,
                      backgroundColor: "#ff7840",
                      boxShadow: "unset",
                    }}
                    onClick={handleDecrease}
                  >
                    <FaMinus />
                  </Button>
                  <Typography variant="body1" fontWeight={550}>
                    {quantity}
                  </Typography>{" "}
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: "10px",
                      height: 30,
                      backgroundColor: "#ff7840",
                      boxShadow: "unset",
                    }}
                    onClick={handleIncrease}
                  >
                    <FaPlus />
                  </Button>
                </Stack>
              </Stack>
              {toppings.length > 0 && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    mt={2}
                    mb={1}
                  >
                    More toppings
                  </Typography>
                  <Grid container spacing={2}>
                    {toppings.map((topping) => {
                      const isSelected = selectedToppings.some(
                        (t) => t._id === topping._id
                      );

                      return (
                        <Grid item xs={6} key={topping._id}>
                          <Button
                            variant={isSelected ? "contained" : "outlined"}
                            sx={{
                              width: "165px",
                              textTransform: "none",
                              color: "#333",

                              border: isSelected
                                ? "1px solid #ff7840"
                                : "1px solid rgba(3,3,3,0.2)",
                              borderRadius: "2px",
                            }}
                            onClick={() => handleToggleTopping(topping)}
                          >
                            <Stack gap={1} alignItems="center">
                              <Typography>{topping.name}</Typography>
                              <Typography>
                                {formatCurrency(topping.price)}
                              </Typography>
                            </Stack>
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
              <Box
                sx={{
                  position: "fixed",
                  bottom: 15,
                  left: 0,
                  right: 0,
                  height: "35px",
                  backgroundColor: "white",
                  boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleAddToCart}
                  sx={{
                    bgcolor: "#ff7840",
                    textTransform: "none",
                    fontSize: 18,
                    height: 50,
                  }}
                >
                  {translations[language as "en" | "jp" | "vn"].addToCart}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <p>Please select a product</p>
        )}
      </Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default List;
